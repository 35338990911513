import { Frame, TopBar, Text, Icon, InlineStack, Button, Popover, ActionList, Box } from '@shopify/polaris';
import { NotificationIcon, CodeIcon, PlusIcon } from '@shopify/polaris-icons';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as headerDucks from '../../ducks/header';
import * as authDucks from '../../ducks/auth';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import StepBubble from './StepBubble';
import BiilingWarning from './BiilingWarning';
import { AlertTriangleIcon, Uk_Flag, Spain_Flag, flag_french, flag_germany } from '../../img';
import { useLanguage } from '../../LanguageContext';
import { CaretDownIcon } from '@shopify/polaris-icons';
import { checkPermission } from '../../services/permissions';
import IntroVideo from './IntroVideo';

let siteLogo = '';
let _favIcn = '';

function Header(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { translate, setLanguage } = useLanguage();

  const [userMenuActive, setUserMenuActive] = useState(false);
  const [mobiNaviActive, setMobiNaviActive] = useState(false);
  const [isNotifMenu, setIsNotifMenu] = useState(false);
  const [isInstlMenu, setIsInstlMenu] = useState(false);
  const [activity, setActivity] = useState([]);
  const [unSeenCount, setUnSeenCount] = useState(0);
  const [isFound, setIsFound] = useState(false);
  const [popoverActive, setPopoverActive] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const userMenuOpenClose = useCallback(() => setUserMenuActive((userMenuActive) => !userMenuActive), []);
  const mobiNavOpenClose = useCallback(() => setMobiNaviActive((mobiNaviActive) => !mobiNaviActive), []);
  const notifMenuOpenClose = () => setIsNotifMenu(!isNotifMenu);
  const instlMenuOpenClose = () => setIsInstlMenu(!isInstlMenu);

  useEffect(() => {
    dispatch(authDucks.getProfile());
    dispatch(headerDucks.getActivity());
  }, []);

  const profileData = useSelector(state => state.auth.profile);
  const activityData = useSelector(state => state.header.activity);


  // Toggles popover visibility
  const togglePopoverActive = () => setPopoverActive((active) => !active);


  // Mock country data
  const countryOptions = [
    { label: 'Eng', value: 'en', code: 'US', flag: '🇺🇸', icon: Uk_Flag },
    { label: 'Spa', value: 'es', code: 'ES', flag: 'es', icon: Spain_Flag },
    { label: 'Ger', value: 'de', code: 'DE', flag: 'de', icon: flag_germany },
    { label: 'Fre', value: 'fr', code: 'FR', flag: 'fr', icon: flag_french },
  ];

  const countryFullOptions = [
    { label: 'English', value: 'en', code: 'US', flag: '🇺🇸', icon: Uk_Flag },
    { label: 'Spanish', value: 'es', code: 'ES', flag: 'es', icon: Spain_Flag },
    { label: 'German', value: 'de', code: 'DE', flag: 'de', icon: flag_germany },
    { label: 'French', value: 'fr', code: 'FR', flag: 'fr', icon: flag_french },
  ];

  useEffect(() => {
    if (profileData) {
      if (profileData.csub) {
        localStorage.setItem('csub', profileData.csub);
      }
      if (profileData.isbusr && profileData.isbusr === true && profileData.userTeam) {
        localStorage.setItem('username', profileData.userTeam.fn ? profileData.userTeam.fn : 'User');
      }
      else {
        localStorage.setItem('username', profileData.fn ? profileData.fn : profileData.un);
      }
      localStorage.setItem('e', profileData.e);
      localStorage.setItem('ti', profileData.ti);
      localStorage.setItem('csub', profileData.csub);
      if (profileData.vstp && profileData.vstp.plx && profileData.vstp.plx.st !== true) {
        localStorage.setItem('isPixelIns', false);
      }

      if (profileData.isbusr === true) {
        //white_label
        localStorage.setItem('isbusr', true);
      }
      else {
        //white_label
        localStorage.setItem('isbusr', false);
      }
      if (profileData.pvd === 'shopify' || profileData.pvd === 'wix' || profileData.pvd === 'thinkific' || profileData.pvd === 'bigcommerce' || profileData.pvd === 'duda') {
        localStorage.setItem('pvd', profileData.pvd);
      } else {
        localStorage.setItem('pvd', 'local');
      }
      if (profileData.utyp === 'dealify' || profileData.utyp === 'appsumo') {
        localStorage.setItem('utyp', profileData.utyp);
      }
    }

  }, [profileData]);

  //set count of unseen notification
  useEffect(() => {
    if (activityData?.length > 0) {
      let tempCount = 0;
      for (let i = 0; i < activityData.length; i++) {
        if (activityData[i].iv === false) {
          setIsFound(true);
          tempCount++
        }
      }
      setUnSeenCount(prevCount => prevCount + tempCount);
      setActivity(activityData);
    }
  }, [activityData]);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      const selectedOption = countryOptions.find((option) => option.value === savedLanguage);
      setSelectedLanguage(selectedOption || countryOptions[0]);
    } else {
      setSelectedLanguage(countryOptions[0]);
    }
  }, []);

  const handleLanguageChange = (value) => {
    const selectedOption = countryOptions.find((option) => option.value === value);
    setSelectedLanguage(selectedOption);
    setLanguage(value);
    setPopoverActive(false);
  };

  //updUserView call
  const handleNotificationClick = () => {
    if (unSeenCount > 0) {
      dispatch(headerDucks.updUserView());
      setIsFound(false);
    }
  };

  //click on activity list item
  const activityClick = (data) => {
    navigate(data.rc);

    if (data.ic !== true) {
      dispatch(headerDucks.updateUserClick({ id: data._id }));
    }
  }

  const handleLogout = () => {
    setTimeout(() => {
      if (window.Froged) {
        window.Froged('logout');
      }
    }, 1000);
    localStorage.removeItem('restoreId');
    localStorage.removeItem('authToken');
    localStorage.removeItem('username');
    localStorage.removeItem('e');
    localStorage.removeItem('ti');
    localStorage.removeItem('pvd');
    localStorage.removeItem('csub');
    localStorage.removeItem('istm');
    localStorage.removeItem('role');
    localStorage.removeItem('isbusr');//white_label
    localStorage.removeItem('utyp');
    localStorage.removeItem('isAppsumo');
    localStorage.removeItem('alg');
    localStorage.removeItem('fvicn');
    localStorage.removeItem('isMaster');
    localStorage.removeItem('isPixelIns');
    localStorage.removeItem('is_hide_blackfriday');
    localStorage.removeItem('loginTime');
    localStorage.removeItem('UTM_SIGNUP');
    localStorage.removeItem('rdt_url');
    localStorage.removeItem('iskponbrdng');
    localStorage.removeItem('iscmpltdonbrdng');
    // cookies.remove('W_APP_TI');
    // setTimeout(() => {
    window.location.href = '/login';
    // }, 2000);
  }

  const handleInstallPixel = () => {
    navigate('/pixel'); // Navigate to "pixel" route
  }

  const searchFieldMarkup = (
    <div style={{ display: 'flex' }}>
      <div className="top-bar-custom" style={{ marginRight: '5px' }}>
        {localStorage.getItem('pvd') !== 'wix' && localStorage.getItem('pvd') !== 'shopify' && localStorage.getItem('isbusr') === 'false' && profileData?.vstp?.plx?.st === false && (
          <TopBar.Menu
            activatorContent={
              <div className='alert-pixel-block'
                style={{
                  display: 'flex',
                  fontSize: '12px',
                  fontWeight: 'var(--p-font-weight-medium)',
                  paddingLeft: '3px',
                  paddingRight: '3px',
                  padding: '5px',
                  marginRight: '5px',
                  border: '1px solid #E51C00',
                  borderRadius: '10px',
                  backgroundColor: 'rgba(229, 28, 0, 0.1)',
                }}
                onClick={handleInstallPixel}
              >
                <InlineStack gap={200} blockAlign="center">
                  <img src={AlertTriangleIcon} width={30} alt="" />
                  <Text>
                    {translate('headerMenu').pixelLabel}
                    {/* WiserNotify pixel code is not installed on your site - */}
                  </Text>
                  <div id="btnInstall">
                    <Button variant="primary" tone="critical">
                      {translate('headerMenu').Installnow}
                      {/* Install now */}
                    </Button>
                  </div>
                </InlineStack>
              </div>
            }
          />
        )}
      </div>

    </div>
  );

  const handleAddNotif = () => {
    // window.location.href = '/notification';
    navigate('/socialproof?typ=socialproof');
    // changeNameValue({ isConfiguration: true });
  }

  let isShwAddNtfBtn = true;
  let _path = window.location.pathname;
  if (_path === '/socialproof' || _path === '/widgets' || _path === '/inline' || _path === '/performance') {
    isShwAddNtfBtn = false;
  }

  const secondaryMenuMarkup = (
    <div style={{ display: 'flex' }}>

      {checkPermission('notification', 'isadd', profileData) && isShwAddNtfBtn &&
        <div className='top-bar-custom top-bar-ntfBtn' style={{ marginRight: '5px' }}>
          <TopBar.Menu
            activatorContent={
              <>
                {/* {(profileData?.vstp?.plx?.st || localStorage.getItem('isbusr') === 'true') && ( */}
                  <div
                    className="pixlelinstall sadas"
                    style={{
                      display: 'flex',
                      fontSize: '12px',
                      fontWeight: 'var(--p-font-weight-medium)',
                      paddingLeft: '3px',
                      paddingRight: '3px',
                      marginRight: '5px',
                    }}
                    onClick={() => handleAddNotif()}
                  >
                    <Icon source={PlusIcon} />
                    <Text id='addNtf-width'>
                      {translate('notification').AddNotification}
                      {/* Install pixel */}
                    </Text>
                  </div>
                {/* )} */}

              </>
            } />
        </div>
      }

      <div className='top-bar-custom' style={{ marginRight: '5px' }}>
        {localStorage.getItem('pvd') !== 'wix' && localStorage.getItem('pvd') !== 'shopify' && (
          <TopBar.Menu
            activatorContent={
              <>
                {(profileData?.vstp?.plx?.st || localStorage.getItem('isbusr') === 'true') && (
                  <div
                    className="pixlelinstall sadas"
                    style={{
                      display: 'flex',
                      fontSize: '12px',
                      fontWeight: 'var(--p-font-weight-medium)',
                      paddingLeft: '3px',
                      paddingRight: '3px',
                      marginRight: '5px',
                    }}
                    onClick={handleInstallPixel}
                  >
                    <Icon source={CodeIcon} />
                    <Text id='install-width'>
                      {translate('headerMenu').Installnow}
                      {/* Install pixel */}
                    </Text>
                  </div>
                )}

              </>
            }
          />
        )}
      </div>

      <div style={{}} id='langtrans'>
        <Popover
          active={popoverActive}
          activator={
            <div
              onClick={togglePopoverActive}
              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginRight: '5px' }}
            >
              <InlineStack blockAlign="center" id='dd-lang-tran'>
                <img className='dd-flag-img' src={selectedLanguage.icon} alt="" />
                <Box id='lang-arrow' paddingBlockStart={100}>
                  <Text as="span" style={{ fontSize: '14px' }}>
                    {selectedLanguage?.label}
                  </Text>
                  <Icon source={CaretDownIcon} tone="base" />
                </Box>
              </InlineStack>
            </div>
          }
          onClose={togglePopoverActive}
        >
          <ActionList
            items={countryFullOptions.map((option) => ({
              content: (
                <InlineStack>
                  <img src={option.icon} width={30} alt="" style={{ padding: "4px" }} />
                  <Box>
                    <Text as="span" style={{ fontSize: '14px' }}>
                      {option?.label}
                    </Text>
                  </Box>
                </InlineStack>
              ),
              onAction: () => handleLanguageChange(option.value),
            }))}
          />
        </Popover>
      </div>

      <TopBar.Menu
        activatorContent={
          <div onClick={handleNotificationClick}>
            {isFound === true && <span className='notif_unSenCnt' >{unSeenCount}</span>}
            <Icon source={NotificationIcon} tone="base" />
            <Text as="span" visuallyHidden>Secondary menu</Text>
          </div>
        }
        open={isNotifMenu}
        onOpen={notifMenuOpenClose}
        onClose={notifMenuOpenClose}
        actions={[{
          items: activity.length > 0 ? activity.map((data) => {
            return {
              content: (
                <Text tone={data.ic === false ? "success" : "default"}>
                  {data.m}
                </Text>
              ),
              id: data._id,
              onAction: () => activityClick(data),
            };
          })
            :
            [{ content: 'no data found' }]
        }]}
      />
    </div>
  );

  // const secondaryMenuMarkup = (
  //   <div style={{ height: '0px', display: 'flex' }}>
  //     <div className='top-bar-custom' style={{ marginRight: '5px' }}>
  //       {localStorage.getItem('pvd') !== 'wix' && localStorage.getItem('pvd') !== 'shopify' && (

  //         // <TopBar.Menu
  //         //   activatorContent={
  //         //     <>
  //         //       <div className={profileData?.vstp?.plx?.st === false ? "step-pixel" : ""}></div>
  //         //       <div
  //         //         className="pixlelinstall"
  //         //         style={{
  //         //           display: 'flex',
  //         //           fontSize: '12px',
  //         //           fontWeight: 'var(--p-font-weight-medium)',
  //         //           paddingLeft: '3px',
  //         //           paddingRight: '3px',
  //         //           marginRight: '5px',
  //         //         }}
  //         //         onClick={handleInstallPixel}
  //         //       >
  //         //         <Icon source={CodeIcon} />
  //         //         <Text>
  //         //           Install pixel
  //         //           {profileData?.vstp?.plx?.st === false && <span className="blink_me"> pending</span>}
  //         //         </Text>
  //         //       </div>
  //         //     </>
  //         //   }

  //         // open={isInstlMenu}
  //         // onOpen={instlMenuOpenClose}
  //         // onClose={instlMenuOpenClose}
  //         // onAction={handleInstallPixel}
  //         // />
  //         <TopBar.Menu
  //           activatorContent={
  //             <>
  //               {(profileData?.vstp?.plx?.st || localStorage.getItem('isbusr') === 'true') && (
  //                 <div
  //                   className="pixlelinstall sadas"
  //                   style={{
  //                     display: 'flex',
  //                     fontSize: '12px',
  //                     fontWeight: 'var(--p-font-weight-medium)',
  //                     paddingLeft: '3px',
  //                     paddingRight: '3px',
  //                     marginRight: '5px',
  //                   }}
  //                   onClick={handleInstallPixel}
  //                 >
  //                   <Icon source={CodeIcon} />
  //                   <Text id='install-width'>
  //                     {translate('headerMenu').Installnow}
  //                     {/* Install pixel */}
  //                   </Text>
  //                 </div>
  //               )}

  //             </>
  //           }

  //         // open={isInstlMenu}
  //         // onOpen={instlMenuOpenClose}
  //         // onClose={instlMenuOpenClose}
  //         // onAction={handleInstallPixel}
  //         />
  //       )}
  //     </div>
  //     <TopBar.Menu
  //       activatorContent={
  //         <div onClick={handleNotificationClick}>
  //           {isFound === true && <span className='notif_unSenCnt' >{unSeenCount}</span>}
  //           <Icon source={NotificationIcon} tone="base" />
  //           <Text as="span" visuallyHidden>Secondary menu</Text>
  //         </div>
  //       }
  //       open={isNotifMenu}
  //       onOpen={notifMenuOpenClose}
  //       onClose={notifMenuOpenClose}
  //       actions={[{
  //         items: activity.length > 0 ? activity.map((data) => {
  //           return {
  //             content: (
  //               <Text tone={data.ic === false ? "success" : "default"}>
  //                 {data.m}
  //               </Text>
  //             ),
  //             id: data._id,
  //             onAction: () => activityClick(data),
  //           };
  //         })
  //           :
  //           [{ content: 'no data found' }]
  //       }]}
  //     />
  //   </div>
  // );

  const userMenuActions = [{
    items: [
      { content: translate('headerMenu').Yourprofile, onAction: () => navigate('/profile?profile=true') },
      { content: translate('headerMenu').Rateus, onAction: () => navigate('/profile?rateus=true') },
      { content: translate('headerMenu').Logout, onAction: () => handleLogout() }
    ],
  }];

  let uName = localStorage.getItem('username') || '';
  let userImg = '';

  if (profileData) {
    if (localStorage.getItem('istm') === 'true') {
      userImg = profileData.userTeam.iu || '';
    }
    else {
      userImg = profileData.iu || '';
    };
  }

  //subuser.
  if (localStorage.getItem('isbusr') === 'true') {
    siteLogo = 'https://storage.googleapis.com/wiserimg/user_img/default_company.png';
  } else {
    siteLogo = 'images/wiser_logo_white.svg';
  }
  if (localStorage.getItem('isbusr') === 'true' && profileData && profileData.brand) {

    _favIcn = profileData.brand.fvicn ? profileData.brand.fvicn : profileData.brand.lg ? profileData.brand.lg : 'https://storage.googleapis.com/wiserimg/user_img/default_company.png';
    var link;

    link = document.createElement('link');
    link.rel = 'icon';
    link.href = _favIcn;
    document.head.appendChild(link);
    localStorage.setItem('fvicn', _favIcn);


    if (profileData.brand.bn) {
      document.title = profileData.brand.bn;
    }

    if (profileData.brand.lg) {
      localStorage.setItem('alg', profileData.brand.lg);
      siteLogo = profileData.brand.lg;
    } else {
      localStorage.setItem('alg', 'https://storage.googleapis.com/wiserimg/user_img/default_company.png');
      siteLogo = 'https://storage.googleapis.com/wiserimg/user_img/default_company.png';
    }
  }

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={userMenuActions}
      avatar={userImg}
      name={uName}
      initials={uName ? uName.charAt(0).toUpperCase() : ''}
      open={userMenuActive}
      onToggle={userMenuOpenClose}
    />
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      searchField={searchFieldMarkup}
      secondaryMenu={secondaryMenuMarkup}
      onNavigationToggle={mobiNavOpenClose}
    />
  );

  const logo = {
    progress: 182,
    width: localStorage.getItem('isbusr') === 'true' ? '50' : '100%',
    topBarSource: siteLogo,
    url: '/',
    accessibilityLabel: localStorage.getItem('isbusr') === 'true' ? '' : 'Wiser Notify'
  };
  const isOnboard = window.location.pathname === '/onboarding';
  const provider = localStorage.getItem('pvd');

  return (
    <>
      {
        localStorage.getItem('is_shopify_embed')
          ?
          <Frame>
            {props.component}
            <div className='bottom_biilingWarning'>
              {
                !isOnboard && <BiilingWarning></BiilingWarning>
              }
            </div>
          </Frame>
          :
          <Frame
            logo={isOnboard ? undefined : logo}
            topBar={isOnboard ? undefined : topBarMarkup}
            navigation={isOnboard ? undefined : <Sidebar profileData={profileData} />}
            showMobileNavigation={isOnboard ? undefined : mobiNaviActive}
            onNavigationDismiss={isOnboard ? undefined : mobiNavOpenClose}
          >
            {
              !isOnboard &&
              (provider === 'local' || provider === 'facebook' || provider === 'google') &&
              <StepBubble></StepBubble>
            }
            {props.component}
            <IntroVideo></IntroVideo>
            <div className='bottom_biilingWarning'>
              {
                !isOnboard && <BiilingWarning></BiilingWarning>
              }
            </div>
          </Frame>
      }
    </>
  );
}

export default Header;