import React from 'react';
import { Badge, BlockStack, Box, Button, ButtonGroup, Card, Divider, InlineGrid, InlineStack, Layout, List, Page, Popover, Select, SkeletonBodyText, SkeletonDisplayText, Text, Tooltip } from '@shopify/polaris';
import { plan_blackfriday_tag, checkCircle_icon, planTrue_icon } from '../../img';
import BillingSummery from './BillingSummery';
import AppsumoPlanCard from '../AppsumoPlan/AppsumoPlanCard';
import Parser from 'html-react-parser';
import { useLanguage } from '../../LanguageContext';

function PricingPlanCard(props) {
  const { state, changeNameValue } = props;
  const { translate } = useLanguage();

  const tabs = [
    { tabName: 'month', label: translate('plan').PayMonthly },
    // { tabName: 'year', label: 'Pay yearly - Black Friday Deal' },
    { tabName: 'year', label: translate('plan').PayYearly },
  ];

  const planDescList = [
    // { icon: checkCircle_icon, label: '14 Day money back guarantee' },
    { icon: checkCircle_icon, label: translate('plan').ChangePlan }
  ]

  const basicIncludeList = [
    { icon: planTrue_icon, label: translate('plan').PremiumTemplates, tooltip: translate('plan').PremiumTemplatesTooltip },
    { icon: planTrue_icon, label: translate('plan').UnlimitedNotifications, tooltip: translate('plan').UnlimitedNotificationsTooltip },
    {
      icon: planTrue_icon, label: translate('plan').SocialUrgency, tooltip:
        <BlockStack>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text variant="bodyMd">{translate('plan').SocialUrgencyTooltipBullet1}</Text>
          </div>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text>{translate('plan').SocialUrgencyTooltipBullet2}</Text>
          </div>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text>{translate('plan').SocialUrgencyTooltipBullet3}</Text>
          </div>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text>{translate('plan').SocialUrgencyTooltipBullet4}</Text>
          </div>
        </BlockStack>
    },
    { icon: planTrue_icon, label: translate('plan').MultipleWebsites, tooltip: translate('plan').MultipleWebsitesTooltip },
    { icon: planTrue_icon, label: translate('plan').Integrations, tooltip: translate('plan').IntegrationsTooltip },
    { icon: planTrue_icon, label: translate('plan').LiveChatSupport, tooltip: translate('plan').LiveChatSupportTooltip },
    {
      icon: planTrue_icon, label: translate('plan').ReportingSettings, tooltip:
        <BlockStack>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text variant="bodyMd">{translate('plan').ReportingSettingsTooltipBullet1}</Text>
          </div>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text variant="bodyMd">{translate('plan').ReportingSettingsTooltipBullet2}</Text>
          </div>
        </BlockStack>
    }
  ]

  const proIncludeList = [
    { icon: planTrue_icon, label: translate('plan').BrandingControl, tooltip: translate('plan').BrandingControlTooltip },
    {
      icon: planTrue_icon, label: translate('plan').AbTestingandgoal, tooltip:
        <BlockStack>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text variant="bodyMd">{translate('plan').AbTestingandgoalTooltipBullet1}</Text>
          </div>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text variant="bodyMd">{translate('plan').AbTestingandgoalTooltipBullet2}</Text>
          </div>
        </BlockStack>
    },
    { icon: planTrue_icon, label: translate('plan').CustomCss, tooltip: translate('plan').CustomCssTooltip },
    { icon: planTrue_icon, label: translate('plan').Teammate, tooltip: translate('plan').TeammateTooltip }
  ]

  const growthIncludeList = [
    { icon: planTrue_icon, label: translate('plan').InlineSocial, tooltip: translate('plan').InlineSocialTooltip },
    { icon: planTrue_icon, label: translate('plan').DedicatedAccount, tooltip: translate('plan').DedicatedAccountTooltip },
    { icon: planTrue_icon, label: translate('plan').CustomDesign, tooltip: translate('plan').CustomDesignTooltip },
    { icon: planTrue_icon, label: translate('plan').TenTeammates, tooltip: translate('plan').TenTeammatesTooltip }
  ]

  ////display discount price create discount price text
  let priceTextPro = '';
  let priceTextGrowth = '';

  if (state.plv === 'month' && state.selctdProPlanPrice && state.selctdGrowthPlanPrice) {
    priceTextPro = `<span>$${state.selctdProPlanPrice ? props.formmetNumber(state.selctdProPlanPrice) : '-'}</span>`
    priceTextGrowth = `<span>$${state.selctdGrowthPlanPrice ? props.formmetNumber(state.selctdGrowthPlanPrice) : '-'}</span>`
  }
  else if (state.selctdProPlanPrice && state.selctdGrowthPlanPrice && state.proPlanList && state.growthPlanList) {

    let pro_plan = state.proPlanList.find(item => item.newPrice === state.selctdProPlanPrice);
    let growth_plan = state.growthPlanList.find(item => item.newPrice === state.selctdGrowthPlanPrice);

    if (pro_plan)
      // <p class="desc-prc-grp">
      //   <span>( <span className='discount-price'>$${Math.trunc(pro_plan.newPrice / 12)}</span>
      //     <span className='strikethrough'>$${pro_plan.cf_original_price}</span>  <span className='perMonth'>per month<span> )</span>
      //     </p>
      priceTextPro = `<div>
                         <span>$${pro_plan.cf_original_price ? props.formmetNumber((pro_plan.cf_original_price * 8)) : '-'} </span>
                        <span className='billed_yearly'>${translate('plan').billedYearly}</span> 

                        <p class="desc-prc-grp">   
                          <span className='Polaris-Text--success'>${translate('plan').Save} $${Math.trunc(((pro_plan.cf_original_price * 12) - pro_plan.newPrice))}</span> 
                        </p>
                      </div>
                      `;

    if (growth_plan)
      // <p class="desc-prc-grp">
      //   ( <span className='discount-price'>$${Math.trunc(growth_plan.newPrice / 12)}</span>
      //   <span className='strikethrough'>$${growth_plan.cf_original_price}</span> <span className='perMonth'>per month <span>)
      //   </p>
      priceTextGrowth = `<div>
                            <span>$${growth_plan.cf_original_price ? props.formmetNumber((growth_plan.cf_original_price * 8)) : '-'}  
                              <span className='billed_yearly'>${translate('plan').billedYearly}</span>          
                            </span>
                            <p class="desc-prc-grp">
                              <span className='Polaris-Text--success'>${translate('plan').Save} $${Math.trunc(((growth_plan.cf_original_price * 12) - growth_plan.newPrice))}</span> 
                            </p>
                         </div>`;
  }

  return (
    <div className='page-wrap page-plan-wrap'>
      <Box paddingBlockEnd={500}>
        <Page title={translate('plan').Plan}>
          <Card>
            <BlockStack gap={300}>
              <Text variant='bodyLg' as='h6' fontWeight='semibold'>{translate('plan').SelectPlan}</Text>
              <Box id='lll' paddingInlineStart={700}>
                {/* <InlineStack gap={200} wrap={true} blockAlign='center' id='hhh'> */}
                {planDescList && planDescList.map((data, index) => (
                  <div id='toppos'>
                    <InlineStack key={index} gap={100} blockAlign='center' >
                      <img src={data.icon} alt='' />
                      <Text as='p' fontWeight='regular' tone='subdued'>{data.label}</Text>
                    </InlineStack>
                  </div>
                ))}
                {/* </InlineStack> */}
              </Box>
            </BlockStack>
            <div id='plan_block'>
              <Box paddingBlockEnd={500} paddingBlockStart={0}>
                <Layout>
                  <Layout.Section variant='oneThird'>
                    <Box id='pln_basic_block' padding={400} borderRadius={300}>
                      <BlockStack gap={300}>
                        <Box>
                          <Badge tone="warning">{translate('plan').BasicOnlyYearly}</Badge>
                        </Box>
                        <Text variant='bodySm' as='p' fontWeight='regular'>{translate('plan').BasicSubtitle}</Text>
                        <Divider borderWidth='050' />
                        {props.isLoading ?
                          <Box>
                            <Box width='30%' paddingBlockStart={300}>
                              <SkeletonBodyText lines={1} />
                            </Box>
                            <Box paddingBlock={400}>
                              <SkeletonDisplayText size='medium' maxWidth='100%' />
                            </Box>
                          </Box>
                          :
                          <Box>
                            <Box paddingBlockEnd={300}>
                              <InlineStack gap={100} blockAlign='center'>
                                <Text variant='headingLg' as='h3' fontWeight='semibold'>${state.basicPlanPrice ? state.basicPlanPrice : '-'}</Text>
                                <Text variant='bodyMd' as='p' fontWeight='regular'>{translate('plan').perYear}</Text>
                                {/* {state?.selctdPlanId && state.basicPlan.id === state.selctdPlanId && (
                              <Badge tone="success">Selected</Badge>
                            )} */}
                              </InlineStack>
                            </Box>
                            <Box minHeight='50px'>
                              <Popover
                                fullWidth
                                activator={<Button fullWidth textAlign={'start'}>
                                  <Text variant='bodyMd' fontWeight='medium'>
                                    {state.basicPlanVisitor ? state.basicPlanVisitor : '-'} {translate('plan').VisitorsMonth}
                                  </Text>
                                </Button>}>
                              </Popover>
                            </Box>
                          </Box>
                        }
                        <Box>
                          {(state?.selctdPlanId && state.basicPlan.id === state.selctdPlanId) && (state.profile && state.profile.billing && state.profile.billing.icnc === false) ?
                            <Button id='primary_btn' fullWidth disabled>
                              <Text variant='bodyMd' as='p' fontWeight='semibold'>{translate('plan').CurrentActivePlan}</Text>
                            </Button>
                            :
                            <Button id='primary_btn' fullWidth onClick={() => props.getUpgradeConfirmation('basic')} disabled={state.basicPlanPrice < state.selctdPlanPrice ? true : false}>
                              {/* <Text variant='bodyMd' fontWeight='semibold'>{state.basicPlanPrice > state.selctdPlanPrice ? translate('plan').UpgradeYearly : translate('plan').GetStartedNow}</Text> */}
                              <Text variant='bodyMd' fontWeight='semibold'>{state.basicPlanPrice > state.selctdPlanPrice ? translate('plan').UpgradeYearly : translate('plan').day7FreeTrial}</Text>
                            </Button>
                          }
                        </Box>
                        <InlineStack wrap={false} gap={100} blockAlign='center' align='center'>
                          <img src={checkCircle_icon} alt='icon' />
                          <Text as='p' fontWeight='regular' tone='subdued'>{translate('plan').DayMoneyBack}</Text>
                        </InlineStack>
                        <Box>
                          <BlockStack gap={100}>
                            <Text variant='bodyMd' fontWeight='semibold'>
                              {translate('plan').Includes}
                            </Text>
                            {basicIncludeList && basicIncludeList.map((data, index) => (
                              <InlineStack key={index} gap={100} blockAlign='center' wrap={false}>
                                <img src={data.icon} alt='' />
                                <Tooltip dismissOnMouseOut padding="400" content={data.tooltip} hasUnderline width='wide'>
                                  <Text variant='headingSm' as='p' fontWeight='regular' tone='subdued'>{data.label}</Text>
                                </Tooltip>
                              </InlineStack>
                            ))}
                          </BlockStack>
                        </Box>
                      </BlockStack>
                    </Box>
                  </Layout.Section>
                  {/* <div className='media_tag'>
                    <div className='black_friday_offer_plan'>
                      <InlineStack wrap={true} align='space-between'>
                        <Box>
                          <div className='black_frd_text blackfriday-oneline'>
                            <Text variant="bodySm" as="p" fontWeight="semibold" >
                              Black Friday Offer:
                            </Text>
                            <Text variant="bodySm" as="p" >
                              <span className='get_five_month'>Get 5 Months Free on PRO & GROWTH Yearly Plans!</span>
                            </Text>
                          </div>
                        </Box>
                        <Box id='valid-date-text'>
                          <div className='black_frd_text'>
                            <Text variant="bodyXs" as="p" >
                              Valid till 5th Dec 2024
                            </Text>
                          </div>
                        </Box>
                      </InlineStack>
                      <InlineStack wrap={true} align='space-between'>
                        <Box>
                          <div className='black_frd'>
                            <Text variant="bodySm" as="h6" fontWeight='medium'>
                              Save Up to 42% — Forever! Next Year's Renewal? Same Price!
                            </Text>
                          </div>
                        </Box>
                        <Box id='term-condition-text'>
                          <div className='black_frd'>
                            <Tooltip hasUnderline content={<BlockStack>
                              <Box paddingBlockEnd={200}>
                                <Text variant="headingSm">Black Friday Offer Terms:</Text>
                              </Box>
                              <div className='tooltipBulletin'>
                                <Text variant="bodyMd">&#8226;</Text>
                                <Text variant="bodyMd">Subscribe or upgrade during the Black Friday sale and lock in your discounted rate for life.</Text>
                              </div>
                              <div className='tooltipBulletin'>
                                <Text variant="bodyMd">&#8226;</Text>
                                <Text>Your renewal will always be charged at the same rate as your Black Friday purchase, every year—even if you upgrade in the future.</Text>
                              </div>
                              <div className='tooltipBulletin'>
                                <Text variant="bodyMd">&#8226;</Text>
                                <Text>The deal expires on <span className='text_frd_span'> 5<sup>th</sup> Dec 2024.</span></Text>
                              </div>
                              <div className='tooltipBulletin'>
                                <Text variant="bodyMd">&#8226;</Text>
                                <Text>For any questions, feel free to message us <span className='text_frd_span'> via live chat support.</span></Text>
                              </div>
                            </BlockStack>} width='wide' padding="400" dismissOnMouseOut >
                              <Text variant="bodyXs" as="p">
                                Terms & Conditions
                              </Text>
                            </Tooltip>
                          </div>
                        </Box>
                      </InlineStack>
                    </div>
                  </div> */}
                  <Layout.Section>
                    {/* BLACKFRIDAY */}
                    <Box paddingBlockStart={0}>
                      {/* <InlineStack>
                        <Text variant="headingSm" as="h6" fontWeight='bold'>
                          BLACKFRIDAY OFFER:
                        </Text>
                        <div className='grab_para'>
                          <Text variant="bodySm" as="p">
                            Grab 5 months FREE on PRO & GROWTH yearly plan and save 42%!
                          </Text>
                        </div>
                      </InlineStack> */}
                    </Box>

                    <Box background='bg-surface-tertiary' id='plan_pg_block' borderRadius={300} paddingInline={800} paddingBlockEnd={500}>
                      <InlineStack blockAlign='center' align='center'>
                        <Box background='bg-surface' borderRadius='300' padding={100} borderColor="border" borderWidth="025">
                          <ButtonGroup>
                            {tabs.map((data, index) => {
                              // const localStorageValue = localStorage.getItem('plv');
                              // const variant = localStorageValue ? (localStorageValue === data.tabName ? 'primary' : '') : (state.plv === data.tabName ? 'primary' : '');

                              return (
                                <Button
                                  key={index}
                                  variant={state.plv === data.tabName ? 'primary' : ''}
                                  onClick={() => props.handleTabChange(data)}
                                >
                                  <Text variant='bodyLg' as='p' fontWeight='regular'>
                                    {data.label}
                                  </Text>
                                </Button>
                              );
                            })}
                          </ButtonGroup>
                        </Box>
                      </InlineStack>
                      <Box paddingBlockStart={400}>
                        <InlineGrid columns={{ xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }} gap={400} >
                          <div className='card'>
                            <Box background='bg-surface' padding={400} borderRadius='300' minHeight='429.99px'>
                              <BlockStack gap={300}>
                                <Box>
                                  <Badge tone="info">{translate('plan').Pro}</Badge>
                                </Box>
                                {/* {
                                  state.plv === 'year' &&
                                  <div className='plan-blackfriday-tag'>
                                    <img src={plan_blackfriday_tag} alt="" />
                                  </div>
                                } */}

                                <Text variant='bodySm' as='p' fontWeight='regular'>{translate('plan').ProSubtitle}</Text>
                                {/* <Text variant='bodySm' as='p' fontWeight='regular'>Best for coaching business, selling digital items & funnel builders</Text> */}
                                <Divider />
                                {props.isLoading ?
                                  <Box>
                                    <Box width='30%' paddingBlockStart={300}>
                                      <SkeletonBodyText lines={1} />
                                    </Box>
                                    <Box paddingBlock={400}>
                                      <SkeletonDisplayText size='medium' maxWidth='100%' />
                                    </Box>
                                  </Box>
                                  :
                                  <Box>
                                    <Box paddingBlockEnd={300}>
                                      <div className='pro_box'>
                                        <InlineStack gap={100} blockAlign='center'>
                                          <Text variant='headingLg' as='p' fontWeight='semibold'>
                                            {/* ${state.selctdProPlanPrice ? props.formmetNumber(state.selctdProPlanPrice) : '-'} */}
                                            {Parser(priceTextPro)}
                                          </Text>
                                          {
                                            state.plv === 'month' &&
                                            <Text variant='headingXs' as='p' fontWeight='regular'>{state.plv === 'month' ? translate('plan').perMonth : translate('plan').perYear}</Text>
                                          }
                                          {/* <Text variant='headingXs' as='p' fontWeight='regular'>{state.plv === 'month' ? 'per month' : 'per year'}</Text> */}
                                          {/* <Text variant='headingXs' as='p' fontWeight='regular'>{localStorage.getItem('plv') === 'month' ? 'per month' : 'per year'}</Text> */}
                                          {/* {
                                            state?.selctdPlanId && state.proPlanList.find(plan => plan.cf_plan_quota === state.selctdProPlanVisitor)?.id === state.selctdPlanId && (
                                              <Badge tone="success">Selected</Badge>
                                            )
                                          } */}
                                        </InlineStack>

                                        {
                                          state.plv === 'month' &&
                                          <div className='bulb_para'>
                                            <Text as="p" tone="success">
                                              💡{translate('plan').SavePlans}
                                            </Text>
                                          </div>
                                        }
                                      </div>
                                    </Box>
                                    <Box minHeight='50px'>
                                      {/* <Select
                                  options={state.proOptionList}
                                  onChange={(e) => { changeNameValue({ selctdProPlanVisitor: e }); localStorage.setItem('selctdProPlanVisitor', e); }}
                                  value={localStorage.getItem('selctdProPlanVisitor') ? localStorage.getItem('selctdProPlanVisitor') : state.selctdProPlanVisitor}
                                /> */}
                                      <Select
                                        options={state.proOptionList}
                                        onChange={(e) => changeNameValue({ selctdProPlanVisitor: e })}
                                        value={state.selctdProPlanVisitor}
                                      />
                                    </Box>
                                  </Box>
                                }
                                {(state?.selctdPlanId && state.proPlanList.find(plan => plan.cf_plan_quota === state.selctdProPlanVisitor)?.id === state.selctdPlanId) &&
                                  (state.profile && state.profile.billing && state.profile.billing.icnc === false)
                                  ?
                                  <Button id='primary_btn' fullWidth disabled>
                                    <Text variant='bodyMd' as='p' fontWeight='semibold'>{translate('plan').CurrentActivePlan}</Text>
                                  </Button>
                                  :
                                  <Button id='primary_btn' fullWidth onClick={() => props.getUpgradeConfirmation('pro')} disabled={state.selctdProPlanPrice < state.selctdPlanPrice ? true : false}>
                                    {/* <Text variant='bodyMd' as='p' fontWeight='semibold'>{state.selctdProPlanPrice > state.selctdPlanPrice ? state.plv === 'month' ? translate('plan').UpgradeMonthly : translate('plan').UpgradeYearly : translate('plan').GetStartedNow}</Text> */}
                                    <Text variant='bodyMd' as='p' fontWeight='semibold'>{state.selctdProPlanPrice > state.selctdPlanPrice ? state.plv === 'month' ? translate('plan').UpgradeMonthly : translate('plan').UpgradeYearly : translate('plan').day7FreeTrial}</Text>
                                  </Button>
                                }
                                <InlineStack wrap={false} gap={100} blockAlign='center' align='center'>
                                  <img src={checkCircle_icon} alt='icon' />
                                  <Text as='p' fontWeight='regular' tone='subdued'>{translate('plan').DayMoneyBack}</Text>
                                </InlineStack>
                                <Box>
                                  <Box paddingBlockEnd={100}>
                                    <Text variant='bodyMd' as='p' fontWeight='semibold'>{translate('plan').EverythingIncludesBasic}</Text>
                                  </Box>
                                  <BlockStack gap={100}>
                                    {proIncludeList && proIncludeList.map((data, index) => (
                                      <InlineStack key={index} gap={100} blockAlign='center' wrap={false}>
                                        <img src={data.icon} alt='' />
                                        <Tooltip dismissOnMouseOut padding="400" content={data.tooltip} hasUnderline width='wide'>
                                          <Text variant='headingSm' as='p' fontWeight='regular' tone='subdued'>{data.label}</Text>
                                        </Tooltip>
                                      </InlineStack>
                                    ))}
                                  </BlockStack>
                                </Box>
                              </BlockStack>
                            </Box>
                          </div>
                          <div class="card_Growth">
                            <Box background='bg-surface' padding={400} borderRadius='300'>
                              <BlockStack gap={300}>
                                <Box>
                                  <Badge tone="attention">{translate('plan').Growth}</Badge>
                                </Box>
                                {/* {
                                  state.plv === 'year' &&
                                  <div className='plan-blackfriday-tag'>
                                    <img src={plan_blackfriday_tag} alt="" />
                                  </div>
                                } */}
                                <Text variant='bodySm' as='p' fontWeight='regular'>{translate('plan').GrowthSubtitle}</Text>
                                <Divider />
                                {props.isLoading ?
                                  <Box>
                                    <Box width='30%' paddingBlockStart={300}>
                                      <SkeletonBodyText lines={1} />
                                    </Box>
                                    <Box paddingBlock={400}>
                                      <SkeletonDisplayText size='medium' maxWidth='100%' />
                                    </Box>
                                  </Box>
                                  :
                                  <Box>
                                    <Box paddingBlockEnd={300}>
                                      <div className='pro_box'>

                                        <InlineStack gap={100} blockAlign='center'>
                                          <Text variant='headingLg' as='h3'>
                                            {/* ${state.selctdGrowthPlanPrice ? props.formmetNumber(state.selctdGrowthPlanPrice) : '-'} */}
                                            {Parser(priceTextGrowth)}
                                          </Text>
                                          {
                                            state.plv === 'month' &&
                                            <Text variant='headingXs' as='p' fontWeight='regular'>{state.plv === 'month' ? translate('plan').perMonth : translate('plan').perYear}</Text>
                                          }
                                          {/* <Text variant='bodySm' as='p' >{state.plv === 'month' ? 'per month' : 'per year'}</Text> */}
                                        </InlineStack>

                                        {state.plv === 'month' &&
                                          <div className='bulb_para'>
                                            <Text as="p" tone="success">
                                              💡{translate('plan').SavePlans}
                                            </Text>
                                          </div>
                                        }
                                      </div>
                                    </Box>
                                    <Box minHeight='50px'>
                                      <Select
                                        options={state.growthOptionList}
                                        onChange={(e) => changeNameValue({ selctdGrowthPlanVisitor: e })}
                                        value={state.selctdGrowthPlanVisitor}
                                      />
                                    </Box>
                                  </Box>
                                }
                                {
                                  (state?.selctdPlanId && state.growthPlanList.find(plan => plan.cf_plan_quota === state.selctdGrowthPlanVisitor)?.id === state.selctdPlanId)
                                    && (state.profile && state.profile.billing && state.profile.billing.icnc === false) ?
                                    <Button id='primary_btn' fullWidth disabled>
                                      <Text variant='bodyMd' as='p' fontWeight='semibold'>{translate('plan').CurrentActivePlan}</Text>
                                    </Button>
                                    :
                                    <Button id='primary_btn' fullWidth onClick={() => props.getUpgradeConfirmation('growth')} disabled={state.selctdGrowthPlanPrice < state.selctdPlanPrice ? true : false}>
                                      {/* <Text variant='bodyMd' as='p' fontWeight='semibold' >{state.selctdGrowthPlanPrice > state.selctdPlanPrice ? state.plv === 'month' ? translate('plan').UpgradeMonthly : translate('plan').UpgradeYearly : translate('plan').GetStartedNow}</Text> */}
                                      <Text variant='bodyMd' as='p' fontWeight='semibold' >{state.selctdGrowthPlanPrice > state.selctdPlanPrice ? state.plv === 'month' ? translate('plan').UpgradeMonthly : translate('plan').UpgradeYearly : translate('plan').day7FreeTrial}</Text>
                                    </Button>
                                }
                                <InlineStack gap={100} blockAlign='center' align='center' wrap={false}>
                                  <img src={checkCircle_icon} alt='icon' />
                                  <Text as='p' fontWeight='regular' tone='subdued'>{translate('plan').DayMoneyBack}</Text>
                                </InlineStack>
                                <Box>
                                  <Box paddingBlockEnd={100}>
                                    <Text variant='bodyMd' as='p' fontWeight='semibold'>
                                      {translate('plan').EverythingIncludesPro}
                                    </Text>
                                  </Box>
                                  <BlockStack gap={100}>
                                    {
                                      growthIncludeList && growthIncludeList.map((data, index) => (
                                        <InlineStack key={index} gap={100} blockAlign='center' wrap={false}>
                                          <img src={data.icon} alt='' />
                                          <Tooltip dismissOnMouseOut padding="400" content={data.tooltip} hasUnderline width='wide'>
                                            <Text variant='headingSm' as='p' fontWeight='regular' tone='subdued'>{data.label}</Text>
                                          </Tooltip>
                                        </InlineStack>
                                      ))
                                    }
                                  </BlockStack>
                                </Box>
                              </BlockStack>
                            </Box>
                          </div>

                        </InlineGrid>
                      </Box>
                    </Box>
                  </Layout.Section>
                </Layout>
              </Box>

              {/*Get started for free */}
              {/* {state.profile && state.profile.utyp !== 'appsumo'
                && (!state.profile.csub || state.profile.csub === undefined || state.profile.csub === null || state.profile.csub === '')
                &&
                <Box padding={400} borderColor='border' borderWidth='025' borderRadius='300' shadow='100'>
                  <InlineStack align='space-between' blockAlign='center'>
                    <BlockStack gap={100}>
                      <Text variant='bodyMd' as='h6' fontWeight='semibold' >{translate('plan').GetStartedForFree}</Text>
                      <List type="bullet">
                        <InlineStack>
                          <List.Item><Text tone='subdued'>{translate('plan').DisplayVisitors}</Text></List.Item>
                          <List.Item><Text tone='subdued'>{translate('plan').ActiveNotification}</Text></List.Item>
                        </InlineStack>
                        <List.Item>
                          <Text tone='subdued'>{translate('plan').LimitedTemplates}</Text>
                        </List.Item>
                      </List>
                    </BlockStack>
                    <BlockStack gap={100}>
                      <Button id='primary_btn' onClick={() => props.handleStart()}>{translate('plan').StartFreePlan}</Button>
                      <InlineStack gap={100} blockAlign='center' align='center'>
                        <img src={checkCircle_icon} alt='icon' />
                        <Text as='p' fontWeight='regular' tone='subdued'>{translate('plan').NoCardRequired}</Text>
                      </InlineStack>
                    </BlockStack>
                  </InlineStack>
                </Box>} */}

              {/* Have more than 1 million visitors?  */}
              <Box paddingBlockStart={400}>
                <Box padding={400} borderColor='border' borderWidth='025' borderRadius='300' shadow='100'>
                  <InlineStack align='space-between' blockAlign='center'>
                    <BlockStack gap={100}>
                      <Text variant='bodyMd' as='h6' fontWeight='semibold' >{translate('plan').TitleVisitors}</Text>
                      <Text tone="subdued" variant='bodyMd' as='p' fontWeight='regular'>{translate('plan').SubtitleVisitors}</Text>
                    </BlockStack>
                    <Button id='chat_Us' onClick={() => props.handleChatButton()}>{translate('plan').ButtonTextVisitors}</Button>
                  </InlineStack>
                </Box>
              </Box>

            </div>
          </Card>

          {state.lstAddon && state.profile &&
            (
              (state.profile.addonid !== undefined && state.profile.addonid !== 'null') ||
              (state.profile.csub !== undefined && state.profile.csub !== 'null' && state.profile.csub !== 'free' && state.profile.isbusr !== true)
            ) &&
            state.lstAddon.length > 0 && state.profile.utyp !== 'appsumo'
            &&
            state.lstAddon.map((addon, i) => {
              return (
                <Box key={i} paddingBlockStart={600}>
                  <BlockStack gap={200}>
                    <Text as='h4' variant='bodyLg' fontWeight='semibold'>{translate('plan').AddOn}</Text>
                    <Card id='Review_wall'>
                      <div className='addon-wrap addon-socialwall'>
                        <InlineStack blockAlign='center' gap={{ lg: 3200, xs: 200 }}>
                          <BlockStack gap={200}>
                            <Text variant='bodyMd' fontWeight='semibold'>{addon.name}</Text>
                            <Box>
                              <List type="bullet">
                                <InlineStack gap={700}>
                                  <List.Item><Text tone='subdued'>{translate('plan').ReviewFeeds}</Text></List.Item>
                                  <List.Item><Text tone='subdued'>{translate('plan').SocialFeeds}</Text></List.Item>
                                  <List.Item><Text tone='subdued'>{translate('plan').UnlimitedWalls}</Text></List.Item>
                                </InlineStack>
                              </List>
                            </Box>
                          </BlockStack>
                          <InlineStack gap={100} wrap={false}>
                            <Text variant='bodySm' as='p' textDecorationLine='line-through' tone='subdued'>$192</Text>
                            <Text variant='headingXl' as='h3' fontWeight='semibold'>$120</Text>
                            <Text variant='bodyMd' as='h3' fontWeight='regular'>{translate('plan').perYear}</Text>
                          </InlineStack>
                          {state.profile.addonid === addon.id ?
                            <Badge tone='success'>{translate('plan').Active}</Badge>
                            :
                            <Button id='primary_btn' onClick={() => props.handlePurchaseChargebeeAddon(addon.id)}>{translate('plan').BuyThisAddon}</Button>
                          }
                        </InlineStack>
                      </div>
                    </Card>
                  </BlockStack>
                </Box>
              )
            })
          }

          {/* appsumo user type */}
          {
            (state.lstAddon && state.profile.utyp === 'appsumo' && state.lstAddon.length > 0)
            &&
            state.lstAddon.map((addon, i) => {
              return (
                <Box key={i} paddingBlockStart={600}>
                  <BlockStack gap={200}>
                    <Text as='h4' variant='bodyLg' fontWeight='semibold'>{translate('plan').AddOn}</Text>
                    <Card id='Review_wall'>
                      <div className='addon-wrap addon-socialwall'>
                        <InlineStack blockAlign='center' gap={{ lg: 3200, xs: 200 }}>
                          <BlockStack gap={200}>
                            <Text variant='bodyMd' fontWeight='semibold'>{addon.name}</Text>
                            <Box>
                              <List type="bullet">
                                <InlineStack gap={700}>
                                  <List.Item><Text tone='subdued'>{translate('plan').ReviewFeeds}</Text></List.Item>
                                  <List.Item><Text tone='subdued'>{translate('plan').SocialFeeds}</Text></List.Item>
                                  <List.Item><Text tone='subdued'>{translate('plan').UnlimitedWalls}</Text></List.Item>
                                </InlineStack>
                              </List>
                            </Box>
                          </BlockStack>
                          <InlineStack gap={100} wrap={false}>
                            <Text variant='bodySm' as='p' textDecorationLine='line-through' tone='subdued'>$192</Text>
                            <Text variant='headingXl' as='h3' fontWeight='semibold'>$120</Text>
                            <Text variant='bodyMd' as='h3' fontWeight='regular'>{translate('plan').perYear}</Text>
                          </InlineStack>
                          {state.profile.addonid === addon.id ?
                            <Badge tone='success'>{translate('plan').Active}</Badge>
                            :
                            <Button id='primary_btn' onClick={() => props.handlePurchaseChargebeeAddon(addon.id)}>{translate('plan').BuyThisAddon}</Button>
                          }
                        </InlineStack>
                      </div>
                    </Card>
                  </BlockStack>
                </Box>
              )
            })
          }

          {/* <Box paddingBlockStart={300}>
        <BlockStack gap={200}>
          <Card id='Review_wall'>
            <InlineStack blockAlign='center' gap={{ lg: 600, xs: 200 }}>
              <BlockStack gap={200}>
                <Text variant='bodyMd' fontWeight='semibold'>Whit label & Sub users</Text>
                <Box width='441px'>
                  <List type="bullet">
                    <InlineStack gap={600}>
                      <List.Item>Your branded Dashboard</List.Item>
                      <List.Item>Includes 10 sub users then $5 per user</List.Item>
                    </InlineStack>
                  </List>
                </Box>
              </BlockStack>
              <Box>
                <InlineStack gap={100}>
                  <Box>
                    <Text as='p' variant='bodySm' textDecorationLine='line-through' id='old_Price' >$12</Text>
                  </Box>
                  <Text as='h3' variant='headingXl' id='new_Price'>$19</Text>
                  <Text as='h3' variant='bodySm' >per month</Text>
                </InlineStack>
              </Box>
            </InlineStack>
          </Card>
        </BlockStack>
      </Box> */}

          {
            ((state.profile && state.profile.billing && state.profile.billing.pobj.id !== 'appsumo-spacial') && ((state.profile.billing.icnc !== false && state.profile.billing.iexp === false) || (state.profile.billing.icnc !== true && state.profile.billing.iexp !== true))) &&
            <Box paddingBlock={700}>
              <Box paddingBlockEnd={'200'}>
                <Text as='p' variant='bodyLg' fontWeight='semibold'>{translate('plan').ActivePlan}</Text>
              </Box>
              <BillingSummery props={props} />
            </Box>
          }
          {
            localStorage.getItem('utyp') === 'appsumo' &&
            <Box paddingBlockEnd={400} paddingBlockStart={600}>
              <Box paddingBlockEnd={'200'}>
                <Text as='p' variant='bodyLg' fontWeight='semibold'>{translate('plan').AppSumoPlan}</Text>
              </Box>
              <AppsumoPlanCard />
            </Box>
          }
        </Page>
      </Box >
    </div >
  );
}

export default PricingPlanCard;
