import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { getProfile } from '../ducks/auth';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/pixel';
import { toastify } from '../ducks/toast';

function* getPixel(action) {
  try {
    // yield put(load());
    const res = yield call(api.GET, '/getDomainList', action.payload);

    if (res.status === 'success') {
      yield put(actions.getPixelSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    // yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateDelay(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updateDelay', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getPlatformInt(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/getPlatformInt', action.payload);

    if (res.status === 'success') {
      yield put(actions.getPlatformIntSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* addUserSite(action) {
  try {
    // yield put(load());
    const res = yield call(api.POST, '/addUserSite', action.payload);
    if (res.status === 'success') {
      // yield put(toastify({ type: 'success', msg: res.m}));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//verifyUserPixel
function* verifyUserPixel() {
  try {
    // yield put(load());
    const res = yield call(api.GET, '/verifyUserPixel');
    if (res.status === 'success') {
      if (res.data.isFound === true) {
        yield put(getProfile());
      }
      yield put(actions.verifyUserPixelSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    // yield put(loaded());
  }
  catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* verifyUserPixelByURL(action) {
  try {
    // yield put(load());
    const res = yield call(api.POST, '/verifyUserPixelByURL', action.payload);
    if (res.status === 'success') {
      if (res.data.isFound === true) {
        yield put(actions.verifyUserPixel());
        yield put(toastify({ type: 'success', msg: 'Your pixel code has been detected successfully' }));
      }
      yield put(actions.verifyUserPixelByURLSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    // yield put(loaded());
  } catch (error) {
    // yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetPixel() {
  yield takeLatest(actions.getPixel.type, getPixel);
}

export function* watchUpdateDelayPixel() {
  yield takeLatest(actions.updateDelay.type, updateDelay);
}

export function* watchGetPlatformInt() {
  yield takeLatest(actions.getPlatformInt.type, getPlatformInt);
}

export function* watchAddUserSite() {
  yield takeLatest(actions.addUserSite.type, addUserSite);
}

export function* watchVerifyPixel() {
  yield takeLatest(actions.verifyUserPixel.type, verifyUserPixel);
}

export function* watchVerifyUserPixelByURL() {
  yield takeLatest(actions.verifyUserPixelByURL.type, verifyUserPixelByURL);
}

export default function* rootSaga() {
  yield fork(watchGetPixel);
  yield fork(watchUpdateDelayPixel);
  yield fork(watchGetPlatformInt);
  yield fork(watchAddUserSite);
  yield fork(watchVerifyPixel);
  yield fork(watchVerifyUserPixelByURL);
}
